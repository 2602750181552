const flashcards = {
  'New Mulakshar Words': [
    {
      serial: 188,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'નમ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'झुक',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To bow',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'verbeuge dich ',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 189,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'મન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Mind',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Verstand',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 190,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'મગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मूंग ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Green gram (A lentil)',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Mungbohne',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 191,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'જન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'लोग ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'People',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Menschen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 192,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'જગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जगत ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'World',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Welt',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 193,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'જમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'खाना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To eat',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'iss',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 194,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'ગગન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गगन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sky',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Himmel',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 195,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'મનન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मनन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Contemplation',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Nachsinnen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 196,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'મગજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दिमाग ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Brain',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Gehirn',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 197,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'ગાન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गाना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Song',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Lied',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 198,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'ગાજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'काज (जहाँ बटन डालते हैं)',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Hole where we put the button',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Knopfloch',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 199,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'માગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'माँगना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To ask for it, to request',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'verlange, frage um',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 200,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'માન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मान ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Respect',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Stolz, Respekt',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 201,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'નામ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नाम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Name',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Name',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 202,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'જાગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जागना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Wake up',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'wach auf',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 203,
      level: 'ગ, મ, ન, જ',
      gu: {
        header: 'જાન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जीवन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Life',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Leben',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ગ-મ-ન-જ'
    },
    {
      serial: 204,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जंगल ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Forest',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Wald',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 205,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Groom',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Bräutigam',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 206,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'રસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'रस ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Interest',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Interesse',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 207,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'નર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Male',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'männlich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 208,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'સર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sir',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Herr',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 209,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'સમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'समान ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Equivalent',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'gleich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 210,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વરસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वर्ष ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Year',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Jahr',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 211,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વજન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वजन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Weight',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Gewicht',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 212,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'નજર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नजर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Glance',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Blick',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 213,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'ગરમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गर्म ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Hot',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'heiß',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 214,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'નરમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नरम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Soft',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'weich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 215,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'સરસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अच्छा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Nice',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'schön / gut / super',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 216,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'સમજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'समझ ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Understanding',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Verstehen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 217,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'દર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Every',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'jede / jeder / jedes',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 218,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'દસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दस ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Ten',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'zehn',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 219,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'દમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Caliber',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Fähigkeit / Stärke',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 220,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વાન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'त्वचा का रंग ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Skin complexion',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Hautfarbe',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 221,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'રાસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'डांडिया ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Garba taken with sticks',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Garba mit Stöcken',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 222,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'જમે ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'खाना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To eat',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'isst/ essen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 223,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વેદ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वेद ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Veda',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'der Veda/die Veden',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 224,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વેગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गति ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Speed',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Geschwindigkeit',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 225,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'વેર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बैर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Revenge',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Rache',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 226,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'રમે ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'खेलना',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To play',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'spielst / spielt / spielen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 227,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'નમે ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'झुकना',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'To bow down',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'verbeugst dich / verbeugt / verbeugen sich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 228,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'દેવ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'देव ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Deity',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Himmlische Wesen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 229,
      level: 'વ, ર, સ, દ ',
      gu: {
        header: 'સેવ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बारीक भुजिया ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Savory snacks item',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'salzige Snacks',
        subheader: '',
        speak: ''
      },
      imgUrl: 'વ-ર-સ-દ'
    },
    {
      serial: 230,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'અમર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अमर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Immortal',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'unsterblich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 231,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કરમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'करम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Karma',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Karma',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 232,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'અજગર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अजगर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Python',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Python',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 233,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કાન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'कान ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Ear',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ohr',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 234,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નાક ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नाक ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Nose',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Nase',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 235,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કામ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'काम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Work',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Arbeit',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 236,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'વાર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वार (eg: सोमवार)',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Day of the week',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Tag',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 237,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'જાગ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जाग ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Wake up',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'wach auf',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 238,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નાત ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जाती ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Caste',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Kaste',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 239,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'સાદ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गले से आने वाली आवाज़ ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Voice coming from throat',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Laut der aus dem Hals kommt',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 240,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નાદ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'आवाज़ ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sound',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Geräusch',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 241,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'ગામ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गाँव ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Village',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dorf',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 242,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'બાજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बाज ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Hawk',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Falke',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 243,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'દામ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दाम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Price',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Preis',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 244,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'મામા ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मामा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Maternal Uncle',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Onkel mütterlicherseits',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 245,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કાકા ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'चाचा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Paternal Uncle',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Onkel väterlicherseits',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 246,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'દાદા ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दादा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Grand Father',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Großvater',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 247,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'મારા ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मेरा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Mine',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'mein',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 248,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નાના ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नाना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Maternal Grand Father',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Großvater mütterlicherseits',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 249,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'માસા ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मौसा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: "Maternal Aunt's Husband",
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Mann der Tante mütterlicherseits',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 250,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'બદામ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बादाम ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Almond',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Mandel',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 251,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'ગાજર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गाजर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Carrot',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Karotte',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 252,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'બાકસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'माचिस की डिब्बी ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Matchstick Box',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Streichholzschachtel',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 253,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'આવાજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'शेर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Lion',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Löwe',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 254,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'આવક ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'आवक ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Income',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Einkommen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 255,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'જાવક ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जावक ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Expenditure',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ausgaben',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 256,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'સમાન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'समान ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Equal',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'gleich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 257,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'અનાજ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अनाज ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Grain',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Getreide',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 258,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'વરસાદ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बारिश',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Rain',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Regen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 259,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કદાવર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हट्टा कट्टा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Someone with huge body',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'groß / stark gebaut ',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 260,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'બેન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बहन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sister',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Schwester',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 261,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'સેર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सेर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'A measuring unit',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'eine alte Gewichtseinheit',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 262,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'જેમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जैसा ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Like (the way it is)',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'wie',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 263,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નેક ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नेक ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Honest',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'ehrlich',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 264,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'રેસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दौड़ ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Race',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Rennen',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 265,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'નેમ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नियम, व्रत ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Vow, Rule of conduct',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Gelübde, Verhaltensregel',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 266,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'કેસર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'केसर ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Saffron',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Saffron',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 267,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'એકર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'एकड़ ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'A measuring unit',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Acre (eine Flächenmaßeinheit von 0,4 Hektar)',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 268,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'દાવેદાર ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दावेदार ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'The one who has rightful claim',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Anspruchsberechtigter',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 269,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'આવેદન ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'आवेदन ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Application',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Antrag',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 270,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'બારેમાસ ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बारह महीने ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'All twelve months',
        subheader: '',
        speak: ''
      },
      de: {
        header: '(alle) zwölf Monate',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    },
    {
      serial: 271,
      level: 'ક, બ, અ, છ, આ, એ',
      gu: {
        header: 'છેકછાક ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भूल सुधारना ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'The act of erasing and correcting',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Geschriebenes auslöschen und korrigieren',
        subheader: '',
        speak: ''
      },
      imgUrl: 'ક-બ-અ-છ-આ-એ'
    }
  ],
  Characters: [
    {
      serial: 106,
      level: '1',
      gu: {
        header: 'ક ',
        subheader: 'કમળ',
        speak: 'ક કમળનો ક'
      },
      hi: {
        header: 'क',
        subheader: 'कमल',
        speak: ''
      },
      en: {
        header: 'ka',
        subheader: 'Lotus',
        speak: ''
      },
      de: {
        header: 'ka',
        subheader: 'Lotus',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 107,
      level: '1',
      gu: {
        header: 'ખ ',
        subheader: 'ખટારો ',
        speak: 'ખ ખટારાનો ખ'
      },
      hi: {
        header: 'ख',
        subheader: 'ट्रक',
        speak: ''
      },
      en: {
        header: 'kha',
        subheader: 'Truck',
        speak: ''
      },
      de: {
        header: 'kha',
        subheader: 'Lkw',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 108,
      level: '1',
      gu: {
        header: 'ગ',
        subheader: 'ગરૂડ ',
        speak: 'ગ ગરૂડનો ગ '
      },
      hi: {
        header: 'ग',
        subheader: 'गरूड़ ',
        speak: ''
      },
      en: {
        header: 'ga ',
        subheader: 'eagle',
        speak: ''
      },
      de: {
        header: 'ga ',
        subheader: 'Adler',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 109,
      level: '1',
      gu: {
        header: 'ઘ',
        subheader: 'ઘર',
        speak: 'ઘ ઘરનો ઘ '
      },
      hi: {
        header: 'घ',
        subheader: 'घर',
        speak: ''
      },
      en: {
        header: 'gha ',
        subheader: 'Home',
        speak: ''
      },
      de: {
        header: 'gha ',
        subheader: 'Haus, Zuhause',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 110,
      level: '1',
      gu: {
        header: 'ચ',
        subheader: 'ચકલી',
        speak: 'ચ ચકલીનો ચ'
      },
      hi: {
        header: 'च',
        subheader: 'चिड़िया',
        speak: ''
      },
      en: {
        header: 'cha',
        subheader: 'Sparrow',
        speak: ''
      },
      de: {
        header: 'cha',
        subheader: 'Spatz',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 111,
      level: '1',
      gu: {
        header: 'છ',
        subheader: 'છત્રી',
        speak: 'છ છત્રીનો છ'
      },
      hi: {
        header: 'छ',
        subheader: 'छाता',
        speak: ''
      },
      en: {
        header: 'chha',
        subheader: 'Umbrella',
        speak: ''
      },
      de: {
        header: 'chha',
        subheader: 'Regenschirm',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 112,
      level: '1',
      gu: {
        header: 'જ',
        subheader: 'જિરાફ',
        speak: 'જ જિરાફનો જ '
      },
      hi: {
        header: 'ज',
        subheader: 'जिराफ',
        speak: ''
      },
      en: {
        header: 'ja',
        subheader: 'Giraffe',
        speak: ''
      },
      de: {
        header: 'ja',
        subheader: 'Giraffe',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 113,
      level: '1',
      gu: {
        header: 'ઞ',
        subheader: 'ઝરણું',
        speak: 'ઝ ઝરનાનો ઝ'
      },
      hi: {
        header: 'झ',
        subheader: 'झरना',
        speak: ''
      },
      en: {
        header: 'jha',
        subheader: 'Stream',
        speak: ''
      },
      de: {
        header: 'jha',
        subheader: 'Frühling, Bach',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 114,
      level: '1',
      gu: {
        header: 'ટ',
        subheader: 'ટમેટા',
        speak: 'ટ ટમેટાનો ટ '
      },
      hi: {
        header: 'ट',
        subheader: 'टमाटर',
        speak: ''
      },
      en: {
        header: 'ta',
        subheader: 'Tomato',
        speak: ''
      },
      de: {
        header: 'ta',
        subheader: 'Tomaten',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 115,
      level: '1',
      gu: {
        header: 'ઠ',
        subheader: 'ઠળિયો',
        speak: 'ઠ ઠળિયાનો ઠ '
      },
      hi: {
        header: 'ठ',
        subheader: 'बिज',
        speak: ''
      },
      en: {
        header: 'tha',
        subheader: 'Seed, Fruit stone',
        speak: ''
      },
      de: {
        header: 'tha',
        subheader: 'Kern, Same',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 116,
      level: '1',
      gu: {
        header: 'ડ',
        subheader: 'ડોક્ટર ',
        speak: 'ડ ડોક્ટરનો ડ '
      },
      hi: {
        header: 'ड',
        subheader: 'चिकित्सक ',
        speak: ''
      },
      en: {
        header: 'da ',
        subheader: 'Doctor ',
        speak: ''
      },
      de: {
        header: 'da ',
        subheader: 'Doktor',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 117,
      level: '1',
      gu: {
        header: 'ઢ',
        subheader: 'ઢીંગલી',
        speak: 'ઢ ઢીંગલીનો ઢ'
      },
      hi: {
        header: 'ढ',
        subheader: ' गुडिया',
        speak: ''
      },
      en: {
        header: 'dha',
        subheader: ' Doll',
        speak: ''
      },
      de: {
        header: 'dha',
        subheader: 'Puppe',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 118,
      level: '1',
      gu: {
        header: 'ણ',
        subheader: 'હરણ',
        speak: 'ણ હરણનો ણ'
      },
      hi: {
        header: 'ण',
        subheader: 'हिरन',
        speak: ''
      },
      en: {
        header: 'na ',
        subheader: 'Deer',
        speak: ''
      },
      de: {
        header: 'na ',
        subheader: 'Hirsch',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 119,
      level: '1',
      gu: {
        header: 'ત',
        subheader: 'તલવાર',
        speak: 'ત તલવારનો ત '
      },
      hi: {
        header: 'त',
        subheader: 'तलवार',
        speak: ''
      },
      en: {
        header: 'ta ',
        subheader: 'Sword',
        speak: ''
      },
      de: {
        header: 'ta ',
        subheader: 'Schwert',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 120,
      level: '1',
      gu: {
        header: 'થ',
        subheader: 'થાળી',
        speak: 'થ થાળીનો થ '
      },
      hi: {
        header: 'थ',
        subheader: 'थाली',
        speak: ''
      },
      en: {
        header: 'tha',
        subheader: 'Plate',
        speak: ''
      },
      de: {
        header: 'tha',
        subheader: 'Teller',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 121,
      level: '1',
      gu: {
        header: 'દ',
        subheader: 'દડો',
        speak: 'દ દડાનો દ '
      },
      hi: {
        header: 'द',
        subheader: 'गेंद',
        speak: ''
      },
      en: {
        header: 'da ',
        subheader: 'Ball',
        speak: ''
      },
      de: {
        header: 'da ',
        subheader: 'Ball',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 122,
      level: '1',
      gu: {
        header: 'ધ',
        subheader: 'ધજા',
        speak: 'ધ ધજાનો ધ '
      },
      hi: {
        header: 'ध',
        subheader: 'ध्वज',
        speak: ''
      },
      en: {
        header: 'dha',
        subheader: 'Flag',
        speak: ''
      },
      de: {
        header: 'dha',
        subheader: 'Fahne',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 123,
      level: '1',
      gu: {
        header: 'ન',
        subheader: 'નળ',
        speak: 'ન નળનો ન '
      },
      hi: {
        header: 'न',
        subheader: 'नल',
        speak: ''
      },
      en: {
        header: 'na',
        subheader: 'Tap',
        speak: ''
      },
      de: {
        header: 'na',
        subheader: 'Wasserhahn',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 124,
      level: '1',
      gu: {
        header: 'પ',
        subheader: 'પતંગ',
        speak: 'પ પતંગનો પ'
      },
      hi: {
        header: 'प',
        subheader: 'पतंग',
        speak: ''
      },
      en: {
        header: 'pa',
        subheader: 'Kite',
        speak: ''
      },
      de: {
        header: 'pa',
        subheader: 'Drachen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 125,
      level: '1',
      gu: {
        header: 'ફ',
        subheader: 'ફટાકડા',
        speak: 'ફ ફટાકડાનો ફ '
      },
      hi: {
        header: 'फ',
        subheader: 'पटाखे',
        speak: ''
      },
      en: {
        header: 'fa',
        subheader: 'Fire crackers',
        speak: ''
      },
      de: {
        header: 'fa',
        subheader: 'Feuerwerk',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 126,
      level: '1',
      gu: {
        header: 'બ',
        subheader: 'બકરી',
        speak: 'બ બકરીનો બ'
      },
      hi: {
        header: 'ब',
        subheader: 'बकरी',
        speak: ''
      },
      en: {
        header: 'ba',
        subheader: 'Goat',
        speak: ''
      },
      de: {
        header: 'ba',
        subheader: 'Ziege',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 127,
      level: '1',
      gu: {
        header: 'ભ',
        subheader: 'ભમરડા',
        speak: 'ભ ભામરડાનો ભ'
      },
      hi: {
        header: 'भ',
        subheader: 'लटटू',
        speak: ''
      },
      en: {
        header: 'bha',
        subheader: 'Spinning top',
        speak: ''
      },
      de: {
        header: 'bha',
        subheader: 'Kreisel',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 128,
      level: '1',
      gu: {
        header: 'મ',
        subheader: 'માછલી',
        speak: 'મ માછલી નોમ '
      },
      hi: {
        header: 'म',
        subheader: 'मछली',
        speak: ''
      },
      en: {
        header: 'ma',
        subheader: 'Fish',
        speak: ''
      },
      de: {
        header: 'ma',
        subheader: 'Fisch',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 129,
      level: '1',
      gu: {
        header: 'ય',
        subheader: 'યાક ',
        speak: 'ય યાકનો ય'
      },
      hi: {
        header: 'य',
        subheader: 'याक ',
        speak: ''
      },
      en: {
        header: 'ya ',
        subheader: 'yak ',
        speak: ''
      },
      de: {
        header: 'ya ',
        subheader: 'Yak (ein Rind)',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 130,
      level: '1',
      gu: {
        header: 'ર',
        subheader: 'રમકડાં',
        speak: 'ર રમકડાંનો ર '
      },
      hi: {
        header: 'र',
        subheader: 'खिलौने',
        speak: ''
      },
      en: {
        header: 'ra',
        subheader: 'Toys',
        speak: ''
      },
      de: {
        header: 'ra',
        subheader: 'Spielzeuge',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 131,
      level: '1',
      gu: {
        header: 'લ',
        subheader: 'લખોટી',
        speak: 'લ લખોટીનો લ '
      },
      hi: {
        header: 'ल',
        subheader: 'लखोटी',
        speak: ''
      },
      en: {
        header: 'la',
        subheader: 'marble, Kancha',
        speak: ''
      },
      de: {
        header: 'la',
        subheader: 'Murmel',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 132,
      level: '1',
      gu: {
        header: 'વ',
        subheader: 'વાહન,',
        speak: 'વ વાહનનો વ '
      },
      hi: {
        header: 'व',
        subheader: 'वाहन',
        speak: ''
      },
      en: {
        header: 'va',
        subheader: 'Vehicle',
        speak: ''
      },
      de: {
        header: 'va',
        subheader: 'Fahrzeug',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 133,
      level: '1',
      gu: {
        header: 'શ',
        subheader: 'શરણાઈ',
        speak: 'શ શરણાઈનો શ '
      },
      hi: {
        header: 'श',
        subheader: 'शेहनाई',
        speak: ''
      },
      en: {
        header: 'sha',
        subheader: 'Clarinet',
        speak: ''
      },
      de: {
        header: 'sha',
        subheader: 'Klarinette',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 134,
      level: '1',
      gu: {
        header: 'ષ',
        subheader: 'ષટકોણ',
        speak: 'ષ ષટ્કોણનો ષ '
      },
      hi: {
        header: 'स',
        subheader: 'षटकोण',
        speak: ''
      },
      en: {
        header: 'sha',
        subheader: 'Hexagon',
        speak: ''
      },
      de: {
        header: 'sha',
        subheader: 'Hexagon',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 135,
      level: '1',
      gu: {
        header: 'સ',
        subheader: 'સફરજન',
        speak: 'સ સફરજનનો સ'
      },
      hi: {
        header: 'स',
        subheader: 'सेब',
        speak: ''
      },
      en: {
        header: 'sa',
        subheader: 'Apple',
        speak: ''
      },
      de: {
        header: 'sa',
        subheader: 'Apfel',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 136,
      level: '1',
      gu: {
        header: 'હ',
        subheader: 'હાથી',
        speak: 'હ હાથીનો હ '
      },
      hi: {
        header: 'ह',
        subheader: 'हाथी',
        speak: ''
      },
      en: {
        header: 'ha',
        subheader: 'Elephant',
        speak: ''
      },
      de: {
        header: 'ha',
        subheader: 'Elefant',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 137,
      level: '1',
      gu: {
        header: 'ળ',
        subheader: 'ફળ',
        speak: 'ળ ફળનો ળ'
      },
      hi: {
        header: '',
        subheader: 'फल',
        speak: ''
      },
      en: {
        header: 'la',
        subheader: 'Fruit',
        speak: ''
      },
      de: {
        header: 'la',
        subheader: 'Frucht',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 138,
      level: '1',
      gu: {
        header: 'ક્ષ',
        subheader: 'ક્ષત્રિય,',
        speak: 'ક્ષ ક્ષત્રિયનો ક્ષ'
      },
      hi: {
        header: 'क्ष',
        subheader: 'क्षत्रिय,',
        speak: ''
      },
      en: {
        header: 'ksha',
        subheader: 'Warrior',
        speak: ''
      },
      de: {
        header: 'ksha',
        subheader: 'Krieger',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 139,
      level: '1',
      gu: {
        header: 'જ્ઞ',
        subheader: 'જ્ઞાનકોશ ',
        speak: 'જ્ઞ જ્ઞાનકોશનો જ્ઞ'
      },
      hi: {
        header: 'ज्ञ',
        subheader: 'ज्ञानकोश ',
        speak: ''
      },
      en: {
        header: 'gya ',
        subheader: 'thesurus ',
        speak: ''
      },
      de: {
        header: 'gya ',
        subheader: 'Enzyklopädie',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 140,
      level: '1',
      gu: {
        header: 'અ',
        subheader: 'અનાનસ',
        speak: 'અ અનાનસનો અ '
      },
      hi: {
        header: 'अ',
        subheader: 'अनानास ',
        speak: ''
      },
      en: {
        header: 'a',
        subheader: 'Pineapple',
        speak: ''
      },
      de: {
        header: 'a',
        subheader: 'Ananas',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 141,
      level: '1',
      gu: {
        header: 'આ',
        subheader: 'આગ',
        speak: 'આ આગનો આ'
      },
      hi: {
        header: 'आ',
        subheader: 'आग',
        speak: ''
      },
      en: {
        header: 'aa',
        subheader: 'Fire',
        speak: ''
      },
      de: {
        header: 'aa',
        subheader: 'Feuer',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 142,
      level: '1',
      gu: {
        header: 'ઇ',
        subheader: 'ઇનામ',
        speak: 'ઇ ઇનામનો ઇ '
      },
      hi: {
        header: 'इ',
        subheader: ' इनाम',
        speak: ''
      },
      en: {
        header: 'i',
        subheader: 'Gift, Award',
        speak: ''
      },
      de: {
        header: 'i',
        subheader: 'Geschenk, Preis',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 143,
      level: '1',
      gu: {
        header: 'ઈ',
        subheader: 'ઈંટ',
        speak: 'ઈ ઈંટનો ઈ '
      },
      hi: {
        header: 'ई',
        subheader: 'ईट',
        speak: ''
      },
      en: {
        header: 'ee',
        subheader: 'Brick',
        speak: ''
      },
      de: {
        header: 'ee',
        subheader: 'Ziegelstein',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 144,
      level: '1',
      gu: {
        header: 'ઉ',
        subheader: 'ઉપવન',
        speak: 'ઉ ઉપવનનો ઉ '
      },
      hi: {
        header: 'उ',
        subheader: 'बाग',
        speak: ''
      },
      en: {
        header: 'oo',
        subheader: 'Garden',
        speak: ''
      },
      de: {
        header: 'oo',
        subheader: 'Garten',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 145,
      level: '1',
      gu: {
        header: 'ઊ',
        subheader: 'ઊન',
        speak: 'ઊ ઊનનો ઊ '
      },
      hi: {
        header: 'ऊ',
        subheader: 'ऊन',
        speak: ''
      },
      en: {
        header: 'ou',
        subheader: 'Wool',
        speak: ''
      },
      de: {
        header: 'ou',
        subheader: 'Wolle',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 146,
      level: '1',
      gu: {
        header: 'એ',
        subheader: 'એડી',
        speak: 'એ એડીનો એ '
      },
      hi: {
        header: 'ए',
        subheader: 'एड़ी',
        speak: ''
      },
      en: {
        header: 'ai',
        subheader: 'Heel',
        speak: ''
      },
      de: {
        header: 'ai',
        subheader: 'Ferse, Absatz',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 147,
      level: '1',
      gu: {
        header: 'ઐ',
        subheader: 'ઐનક',
        speak: 'ઐ ઐનકનો ઐ '
      },
      hi: {
        header: 'ऐ',
        subheader: 'ऐनक',
        speak: ''
      },
      en: {
        header: 'ae',
        subheader: 'spectacles, Eyeglass',
        speak: ''
      },
      de: {
        header: 'ae',
        subheader: 'Brille',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 148,
      level: '1',
      gu: {
        header: 'ઓ',
        subheader: 'ઓશીકું',
        speak: 'ઓ ઓશીકાનો ઓ '
      },
      hi: {
        header: 'ओ',
        subheader: 'तकियों',
        speak: ''
      },
      en: {
        header: 'o',
        subheader: 'Pillow',
        speak: ''
      },
      de: {
        header: 'o',
        subheader: 'Kissen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 149,
      level: '1',
      gu: {
        header: 'ઔ',
        subheader: 'ઔષધ',
        speak: 'ઔ ઔષધનો ઔ '
      },
      hi: {
        header: 'औ',
        subheader: 'दवाई',
        speak: ''
      },
      en: {
        header: 'au',
        subheader: 'Medicine',
        speak: ''
      },
      de: {
        header: 'au',
        subheader: 'Medizin',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 150,
      level: '1',
      gu: {
        header: 'અં',
        subheader: 'અંજીર',
        speak: 'અં અંજીરનો અં'
      },
      hi: {
        header: 'अं',
        subheader: 'अंजीर',
        speak: ''
      },
      en: {
        header: 'am',
        subheader: 'Fig',
        speak: ''
      },
      de: {
        header: 'am',
        subheader: 'Feige',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 151,
      level: '1',
      gu: {
        header: 'અઃ',
        subheader: 'નમઃ',
        speak: 'અઃ नमःનો અઃ'
      },
      hi: {
        header: 'अः',
        subheader: 'प्रार्थना, सिर झुकाना',
        speak: ''
      },
      en: {
        header: 'ah',
        subheader: 'Prayer, Bow',
        speak: ''
      },
      de: {
        header: 'ah',
        subheader: 'Gebet, Verbeugung',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 152,
      level: '2',
      gu: {
        header: 'ત્ર',
        subheader: 'તંત્ર',
        speak: 'ત્ર તંત્રનો ત્ર'
      },
      hi: {
        header: 'त्र',
        subheader: 'तंत्र',
        speak: ''
      },
      en: {
        header: 'tra',
        subheader: 'Machinery',
        speak: ''
      },
      de: {
        header: 'tra',
        subheader: 'Maschinerie',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 153,
      level: '2',
      gu: {
        header: 'ઋ',
        subheader: 'ઋતુ',
        speak: 'ઋ ઋતુનો ઋ '
      },
      hi: {
        header: 'ऋ',
        subheader: 'ऋतु ',
        speak: ''
      },
      en: {
        header: 'ru',
        subheader: 'Season, Weather',
        speak: ''
      },
      de: {
        header: 'ru',
        subheader: 'Saison, Jahreszeit',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 154,
      level: '2',
      gu: {
        header: 'ૐ',
        subheader: 'ૐ નમઃ શિવાય',
        speak: 'ૐ ૐ નમઃ શિવાયનો ૐ '
      },
      hi: {
        header: 'ॐ',
        subheader: 'ॐ नमः शिवाय',
        speak: ''
      },
      en: {
        header: 'ohm',
        subheader: 'Om Namh Shivay',
        speak: ''
      },
      de: {
        header: 'ohm',
        subheader: 'Om Namh Shivay',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 155,
      level: '2',
      gu: {
        header: 'રુ',
        subheader: 'ગુરુ',
        speak: 'રુ ગુરુનો રુ'
      },
      hi: {
        header: 'रु ',
        subheader: 'गुरु',
        speak: ''
      },
      en: {
        header: 'ru',
        subheader: 'Master',
        speak: ''
      },
      de: {
        header: 'ru',
        subheader: 'Lehrer, Meister',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 156,
      level: '2',
      gu: {
        header: 'રૂ',
        subheader: 'રૂપિયા',
        speak: 'રૂ રૂપિયાનો રૂ '
      },
      hi: {
        header: 'रू ',
        subheader: 'रूपये',
        speak: ''
      },
      en: {
        header: 'roo',
        subheader: 'Money',
        speak: ''
      },
      de: {
        header: 'roo',
        subheader: 'Geld, Rupees',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 157,
      level: '2',
      gu: {
        header: 'કૃ',
        subheader: 'કૃષ્ણ',
        speak: 'કૃ કૃષ્ણનો કૃ'
      },
      hi: {
        header: 'कृ ',
        subheader: 'कृष्ण',
        speak: ''
      },
      en: {
        header: 'kru',
        subheader: 'Lord Krishna',
        speak: ''
      },
      de: {
        header: 'kru',
        subheader: 'Lord Krishna',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 158,
      level: '2',
      gu: {
        header: 'પૃ',
        subheader: 'પૃથ્વી',
        speak: 'પૃ પૃથ્વીનો પૃ'
      },
      hi: {
        header: 'पृ ',
        subheader: 'पृथ्वी',
        speak: ''
      },
      en: {
        header: 'pru',
        subheader: 'Earth',
        speak: ''
      },
      de: {
        header: 'pru',
        subheader: 'Erde (der Planet)',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 159,
      level: '2',
      gu: {
        header: 'સૃ',
        subheader: 'સૃષ્ટિ',
        speak: 'સૃ સૃષ્ટિનો સૃ '
      },
      hi: {
        header: 'सृ ',
        subheader: 'सृष्टि',
        speak: ''
      },
      en: {
        header: 'sru',
        subheader: 'The Universe',
        speak: ''
      },
      de: {
        header: 'sru',
        subheader: 'Das Universum',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 160,
      level: '2',
      gu: {
        header: 'તૃ',
        subheader: 'તૃણ',
        speak: 'તૃ તૃણનો તૃ'
      },
      hi: {
        header: 'तृ',
        subheader: 'घास',
        speak: ''
      },
      en: {
        header: 'tru',
        subheader: 'Grass',
        speak: ''
      },
      de: {
        header: 'tru',
        subheader: 'Gras',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 161,
      level: '2',
      gu: {
        header: 'હૃ',
        subheader: 'હૃદય',
        speak: 'હૃ હૃદયનો હૃ'
      },
      hi: {
        header: 'हृ ',
        subheader: 'ह्रदय',
        speak: ''
      },
      en: {
        header: 'hru',
        subheader: 'Heart',
        speak: ''
      },
      de: {
        header: 'hru',
        subheader: 'Herz',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 162,
      level: '2',
      gu: {
        header: 'દૃ',
        subheader: 'દ્રષ્ટિ',
        speak: 'દૃ દ્રષ્ટિનો દૃ'
      },
      hi: {
        header: 'द्र ',
        subheader: 'द्रष्टि',
        speak: ''
      },
      en: {
        header: 'dra',
        subheader: 'Vision',
        speak: ''
      },
      de: {
        header: 'dra',
        subheader: 'Sicht, Vision',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 163,
      level: '2',
      gu: {
        header: 'મ્ર ',
        subheader: 'આમ્રફળ',
        speak: 'મ્ર આમ્રફળનો મ્ર'
      },
      hi: {
        header: 'म्र',
        subheader: 'आम्रफल',
        speak: ''
      },
      en: {
        header: 'mra',
        subheader: 'Mango Fruit',
        speak: ''
      },
      de: {
        header: 'mra',
        subheader: 'Mango Frucht',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 164,
      level: '2',
      gu: {
        header: 'ભ્ર',
        subheader: 'ભ્રમર',
        speak: 'ભ્ર ભ્રમરનો ભ્ર'
      },
      hi: {
        header: 'भ्र',
        subheader: 'भौंहे',
        speak: ''
      },
      en: {
        header: 'bhra',
        subheader: 'Eyebrow',
        speak: ''
      },
      de: {
        header: 'bhra',
        subheader: 'Augenbraue',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 165,
      level: '2',
      gu: {
        header: 'પ્ર',
        subheader: 'પ્રોમીસ',
        speak: 'પ્ર પ્રોમિસનો પ્ર'
      },
      hi: {
        header: 'प्र',
        subheader: 'प्रोमीस',
        speak: ''
      },
      en: {
        header: 'pra',
        subheader: 'Promise',
        speak: ''
      },
      de: {
        header: 'pra',
        subheader: 'Versprechen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 166,
      level: '2',
      gu: {
        header: 'ક્ર',
        subheader: 'પ્રક્રિયા',
        speak: 'ક્ર પ્રક્રિયાનો ક્ર'
      },
      hi: {
        header: 'क्र',
        subheader: 'प्रक्रिया',
        speak: ''
      },
      en: {
        header: 'kra',
        subheader: 'Process',
        speak: ''
      },
      de: {
        header: 'kra',
        subheader: 'Prozess',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 167,
      level: '2',
      gu: {
        header: 'ટ્ર',
        subheader: 'ટ્રેન',
        speak: 'ટ્ર ટ્રેનનો ટ્ર '
      },
      hi: {
        header: 'ट्र',
        subheader: 'रेलगाड़ी',
        speak: ''
      },
      en: {
        header: 'tra',
        subheader: 'Train',
        speak: ''
      },
      de: {
        header: 'tra',
        subheader: 'Zug',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 168,
      level: '2',
      gu: {
        header: 'ર્ક',
        subheader: 'તર્ક',
        speak: 'ર્ક તર્કનો ર્ક'
      },
      hi: {
        header: 'र्क',
        subheader: 'तर्क',
        speak: ''
      },
      en: {
        header: 'rka',
        subheader: 'Reasoning',
        speak: ''
      },
      de: {
        header: 'rka',
        subheader: 'Vermutung, Folgerung',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 169,
      level: '2',
      gu: {
        header: 'ર્મ',
        subheader: 'ધર્મ',
        speak: 'ર્મ ધર્મનો ર્મ'
      },
      hi: {
        header: 'र्म',
        subheader: 'धर्म',
        speak: ''
      },
      en: {
        header: 'rma',
        subheader: 'Religion',
        speak: ''
      },
      de: {
        header: 'rma',
        subheader: 'Religion',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 170,
      level: '2',
      gu: {
        header: 'ત્ય',
        subheader: 'જીત્યું',
        speak: 'ત્ય જીત્યાનો ત્ય'
      },
      hi: {
        header: 'त्य',
        subheader: 'जीत लिया',
        speak: ''
      },
      en: {
        header: 'tya',
        subheader: 'Won',
        speak: ''
      },
      de: {
        header: 'tya',
        subheader: 'Gewonnen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 171,
      level: '2',
      gu: {
        header: 'સ્વ',
        subheader: 'સ્વપ્ન',
        speak: 'સ્વ સ્વપ્નનો સ્વ '
      },
      hi: {
        header: 'स्व',
        subheader: 'सपना',
        speak: ''
      },
      en: {
        header: 'swa',
        subheader: 'Dream',
        speak: ''
      },
      de: {
        header: 'swa',
        subheader: 'Traum',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 172,
      level: '2',
      gu: {
        header: 'સ્ત્ર',
        subheader: 'ઈસ્ત્રી',
        speak: 'સ્ત્ર ઈસ્ત્રીનો સ્ત્ર'
      },
      hi: {
        header: 'स्त्र',
        subheader: 'इस्री',
        speak: ''
      },
      en: {
        header: 'stra',
        subheader: 'Iorning',
        speak: ''
      },
      de: {
        header: 'stra',
        subheader: 'Bügeln',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 173,
      level: '2',
      gu: {
        header: 'ત્સ્ય',
        subheader: 'અગસ્ત્ય',
        speak: 'ત્સ્ય અગસ્ત્યનો ત્સ્ય'
      },
      hi: {
        header: 'त्स्य ',
        subheader: 'अगत्स्य',
        speak: ''
      },
      en: {
        header: 'tsya',
        subheader: 'Star, saint',
        speak: ''
      },
      de: {
        header: 'tsya',
        subheader: 'Name eines Stern, Heiligen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 174,
      level: '2',
      gu: {
        header: 'લ્લ',
        subheader: 'મલ્લ',
        speak: 'લ્લ મલ્લનો લ્લ'
      },
      hi: {
        header: 'ल्ल',
        subheader: 'मल्ल',
        speak: ''
      },
      en: {
        header: 'lla',
        subheader: 'Athlete',
        speak: ''
      },
      de: {
        header: 'lla',
        subheader: 'Athlet',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 175,
      level: '2',
      gu: {
        header: 'ન્ન',
        subheader: 'અન્ન',
        speak: 'ન્ન અન્નનો ન્ન '
      },
      hi: {
        header: 'न्न',
        subheader: 'अनाज',
        speak: ''
      },
      en: {
        header: 'nna',
        subheader: 'Grains',
        speak: ''
      },
      de: {
        header: 'nna',
        subheader: 'Essen',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 176,
      level: '2',
      gu: {
        header: 'ત્ત',
        subheader: 'અત્તર',
        speak: 'ત્ત અત્તરનો ત્ત'
      },
      hi: {
        header: 'त्त',
        subheader: 'इत्र',
        speak: ''
      },
      en: {
        header: 'tta',
        subheader: 'Perfume',
        speak: ''
      },
      de: {
        header: 'tta',
        subheader: 'Parfüm',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 177,
      level: '2',
      gu: {
        header: 'દ્ધ',
        subheader: 'બુદ્ધ',
        speak: 'દ્ધ બુદ્ધનો દ્ધ'
      },
      hi: {
        header: 'द्ध',
        subheader: 'बुद्ध',
        speak: ''
      },
      en: {
        header: 'ddha',
        subheader: 'The Lord Buddha',
        speak: ''
      },
      de: {
        header: 'ddha',
        subheader: 'Lord Buddha',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 178,
      level: '2',
      gu: {
        header: 'ધ્ધ ',
        subheader: 'સધ્ધર',
        speak: 'ધ્ધ સધ્ધરનો ધ્ધ'
      },
      hi: {
        header: 'ध्ध',
        subheader: 'संपन्न',
        speak: ''
      },
      en: {
        header: 'dhdha',
        subheader: 'Wealthy',
        speak: ''
      },
      de: {
        header: 'dhdha',
        subheader: 'wohlhabend',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 179,
      level: '2',
      gu: {
        header: 'દ્મ',
        subheader: 'પદ્માસન',
        speak: 'દ્મ પદ્માસનનો દ્મ'
      },
      hi: {
        header: 'द्म',
        subheader: 'पद्मासन',
        speak: ''
      },
      en: {
        header: 'dma',
        subheader: 'Lotus pose',
        speak: ''
      },
      de: {
        header: 'dma',
        subheader: 'Lotussitz',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 180,
      level: '2',
      gu: {
        header: 'દ્વ',
        subheader: 'દ્વાર',
        speak: 'દ્વ દ્વારનો દ્વ'
      },
      hi: {
        header: 'द्व',
        subheader: 'द्वार',
        speak: ''
      },
      en: {
        header: 'dwa',
        subheader: 'Gate',
        speak: ''
      },
      de: {
        header: 'dwa',
        subheader: 'Tor',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 181,
      level: '2',
      gu: {
        header: 'દ્ર',
        subheader: 'ચંદ્ર',
        speak: 'દ્ર ચંદ્રનો દ્ર'
      },
      hi: {
        header: 'द्र',
        subheader: 'चाँद',
        speak: ''
      },
      en: {
        header: 'dra',
        subheader: 'Moon',
        speak: ''
      },
      de: {
        header: 'dra',
        subheader: 'Mond',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 182,
      level: '2',
      gu: {
        header: 'દ્ય',
        subheader: 'વિદ્યાર્થી',
        speak: 'દ્ય વિદ્યાર્થીનો દ્ય'
      },
      hi: {
        header: 'द्य',
        subheader: 'विद्यार्थी',
        speak: ''
      },
      en: {
        header: 'dhya',
        subheader: 'Student',
        speak: ''
      },
      de: {
        header: 'dhya',
        subheader: 'Student',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 183,
      level: '2',
      gu: {
        header: 'હ્ય',
        subheader: 'ગુહ્ય',
        speak: 'હ્ય ગુહ્યનો હ્ય'
      },
      hi: {
        header: 'ह्य',
        subheader: 'गहरा',
        speak: ''
      },
      en: {
        header: ' hya',
        subheader: 'Deep',
        speak: ''
      },
      de: {
        header: ' hya',
        subheader: 'Tief, Geheimnis',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 184,
      level: '2',
      gu: {
        header: 'હ્મ',
        subheader: 'બ્રહ્માંડ',
        speak: 'હ્મ બ્રહ્માંડનો હ્મ'
      },
      hi: {
        header: 'ह्म',
        subheader: 'ब्रह्मांड',
        speak: ''
      },
      en: {
        header: 'hma',
        subheader: 'Universe',
        speak: ''
      },
      de: {
        header: 'hma',
        subheader: 'Universum',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 185,
      level: '2',
      gu: {
        header: 'શ્ર',
        subheader: 'દાદાશ્રી',
        speak: 'શ્ર દાદાશ્રીનો શ્ર'
      },
      hi: {
        header: 'श्र',
        subheader: 'दादाश्री',
        speak: ''
      },
      en: {
        header: 'shra',
        subheader: 'Dadashree',
        speak: ''
      },
      de: {
        header: 'shra',
        subheader: 'Dadashri',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 186,
      level: '2',
      gu: {
        header: 'શ્વ',
        subheader: 'અશ્વ',
        speak: 'શ્વ અશ્વનો શ્વ'
      },
      hi: {
        header: 'श्व',
        subheader: 'घोड़ा',
        speak: ''
      },
      en: {
        header: 'shwa',
        subheader: 'Horse',
        speak: ''
      },
      de: {
        header: 'shwa',
        subheader: 'Pferd',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 187,
      level: '2',
      gu: {
        header: 'શ્ચ',
        subheader: 'પશ્ચિમ',
        speak: 'શ્ચ પશ્ચિમનો શ્ચ'
      },
      hi: {
        header: 'श्च',
        subheader: 'पश्चिम दिशा',
        speak: ''
      },
      en: {
        header: 'shcha',
        subheader: 'West Direction ',
        speak: ''
      },
      de: {
        header: 'shcha',
        subheader: 'Westen',
        speak: ''
      },
      imgUrl: ''
    }
  ],
  Words: [
    {
      serial: 1,
      level: '2',
      gu: {
        header: 'ઇનામ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'इनाम',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'gift, reward',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Geschenk, Auszeichnung, Prämie',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 2,
      level: '1',
      gu: {
        header: 'ખમણ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ढोकले का एक प्रकार',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'one type of dhokla (snack item)',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'eine Art von Dhokla-Snack',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 3,
      level: '1',
      gu: {
        header: 'ઊન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ऊन',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'wool',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Wolle',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 4,
      level: '1',
      gu: {
        header: 'એક',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'एक',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'one',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'eins',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 5,
      level: '1',
      gu: {
        header: 'કમળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'कमल का फूल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'lotus',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Lotus',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 6,
      level: '1',
      gu: {
        header: 'ચરણ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'चरण, पैर',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'feet',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Fuß',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 7,
      level: '1',
      gu: {
        header: 'છત',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'छत',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'roof',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dach',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 8,
      level: '1',
      gu: {
        header: 'જગત',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'विश्व, जगत',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'the world',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'die Welt',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 9,
      level: '1',
      gu: {
        header: 'ઝાડ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पेड़',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'a tree',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'ein Baum',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 10,
      level: '1',
      gu: {
        header: 'ટપાલ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पोस्ट, मेल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'post',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Post',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 11,
      level: '1',
      gu: {
        header: 'ધવલ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सफेद',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'white',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'weiß',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 12,
      level: '1',
      gu: {
        header: 'નયન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'आाँख',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'eye',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Auge',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 13,
      level: '1',
      gu: {
        header: 'ફળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'फल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'fruit',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Obst, Frucht',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 14,
      level: '1',
      gu: {
        header: 'બરફ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बर्फ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'ice',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Eis',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 15,
      level: '1',
      gu: {
        header: 'માણસ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'व्यक्ति, मनुष्य',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'human being',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'menschliches Wesen, Mensch',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 16,
      level: '1',
      gu: {
        header: 'વડ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बरगद का पेड़',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'a banyan tree',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'ein Banyan Baum, Bananenbaum',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 17,
      level: '1',
      gu: {
        header: 'વરાળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भाप',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'vapor',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dampf',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 18,
      level: '1',
      gu: {
        header: 'હળદર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हल्दी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'turmeric',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Kurkuma, Gelbwurz',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 19,
      level: '1',
      gu: {
        header: 'નળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'tap',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Wasserhahn',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 20,
      level: '1',
      gu: {
        header: 'થાળી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'थाली, खाने की प्लेट',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'dinner plate',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Speiseteller',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 21,
      level: '1',
      gu: {
        header: 'હરણ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हिरण',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'deer',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Hirsch, Reh',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 22,
      level: '1',
      gu: {
        header: 'તલ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'तिल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'sesame',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sesam',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 23,
      level: '1',
      gu: {
        header: 'સડક',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सड़क, रास्ता',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'road',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Straße',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 24,
      level: '1',
      gu: {
        header: 'સવાર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सुबह',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'morning',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Morgen',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 25,
      level: '1',
      gu: {
        header: 'યજ્ઞ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'यज्ञ, बलिदान',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'sacrificial fire ritual; sacrifice',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Feueropferritual, Opfer',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 26,
      level: '2',
      gu: {
        header: 'કુકડો',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मुर्गा',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'rooster',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Hahn',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 27,
      level: '2',
      gu: {
        header: 'કેળા',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'केला',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'banana',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Banane',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 28,
      level: '2',
      gu: {
        header: 'કોયલ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'कोयल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'cuckoo bird',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Kuckuck',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 29,
      level: '2',
      gu: {
        header: 'ખજૂર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'खजूर',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'dates',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Datteln',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 30,
      level: '2',
      gu: {
        header: 'ખોખુું',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बक्सा',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'cardboard box',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Pappkarton',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 31,
      level: '2',
      gu: {
        header: 'ગધેડો',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'गधा',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'donkey',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Esel',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 32,
      level: '2',
      gu: {
        header: 'ઘેટું',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भेड़',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'sheep',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Schaf',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 33,
      level: '2',
      gu: {
        header: 'ઘંટ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'घंट',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'bell',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Glocke',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 34,
      level: '2',
      gu: {
        header: 'ચીકુ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'चीकू',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'chickoo',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Chiku (Breiapfel Frucht)',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 35,
      level: '2',
      gu: {
        header: 'ચોપડી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'किताब',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'book',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Buch',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 36,
      level: '2',
      gu: {
        header: 'ઝાકળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ओस की बूँद',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'dew',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Tau',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 37,
      level: '2',
      gu: {
        header: 'ઝેર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'जहर',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'poison',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Gift',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 38,
      level: '2',
      gu: {
        header: 'ટોપલી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'टोपली',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'small basket',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'kleiner Korb',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 39,
      level: '2',
      gu: {
        header: 'થૂંક',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'थूक, लार',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'saliva',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Speichel',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 40,
      level: '2',
      gu: {
        header: 'દીવો',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दीपक',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'lamp',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Lampe',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 41,
      level: '2',
      gu: {
        header: 'નારિયેળ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'नारियल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'coconut',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Kokosnuss',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 42,
      level: '2',
      gu: {
        header: 'પૌઆ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पोहा',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'rice flakes',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Reisflocken',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 43,
      level: '2',
      gu: {
        header: 'ફટાકડા',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'फटाके',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'fire cracker',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Feuerknall, Knallfrosch',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 44,
      level: '2',
      gu: {
        header: 'ફૂલ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'फूल',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'flower',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Blume',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 45,
      level: '2',
      gu: {
        header: 'બિલાડી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बिल्ली',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'cat',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Katze',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 46,
      level: '2',
      gu: {
        header: 'મેથી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मेथी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'fenugreek',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Bockshornklee',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 47,
      level: '2',
      gu: {
        header: 'રીંછ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भालू',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'bear',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Bär',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 48,
      level: '2',
      gu: {
        header: 'સાકર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'शक्कर',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'sugar',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Zucker',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 49,
      level: '2',
      gu: {
        header: 'સીડી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सीढी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'ladder',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Leiter',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 50,
      level: '2',
      gu: {
        header: 'હાડકુું',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हड्डी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'bone',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Knochen',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 51,
      level: '3',
      gu: {
        header: 'ઋષિ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ऋषि ',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'sage, saint',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Weiser, Heiliger',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 52,
      level: '3',
      gu: {
        header: 'કૃષ્ણ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भगवान कृष्ण',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Lord Krishna',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Lord Krishna',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 53,
      level: '3',
      gu: {
        header: 'પૃથ્વી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पृथ्वी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'earth',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Erde',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 54,
      level: '3',
      gu: {
        header: 'હૃદય',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'हृदय',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'heart',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Herz',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 55,
      level: '3',
      gu: {
        header: 'ત્રણ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'तीन',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'three',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'drei',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 56,
      level: '3',
      gu: {
        header: 'પ્રતીક',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'प्रतीक',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'symbol',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Symbol',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 57,
      level: '3',
      gu: {
        header: 'ચક્ર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'चक्र',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'cycle',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Zyklus',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 58,
      level: '3',
      gu: {
        header: 'ભ્રમર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'भौहें',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'eyebrow',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Augenbraue',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 59,
      level: '3',
      gu: {
        header: 'ટ્રાફિક',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ट्राफिक',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'traffic',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Verkehr',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 60,
      level: '3',
      gu: {
        header: 'ટ્રેન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'रेल गाडी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'train',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Zug',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 61,
      level: '3',
      gu: {
        header: 'સ્ત્રી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'स्त्री',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'woman, female',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Frau, weiblich',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 62,
      level: '3',
      gu: {
        header: 'ઇસ્ત્રી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'इस्त्री',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'ironing',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Bügeln',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 63,
      level: '3',
      gu: {
        header: 'વસ્ત્ર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'कपड़े',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'clothes',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Kleidung',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 64,
      level: '3',
      gu: {
        header: 'શસ્ત્ર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'शस्त्र',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'weapon',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Waffe',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 65,
      level: '3',
      gu: {
        header: 'અન્ન / આહાર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अनाज',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'food',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Nahrungsmittel',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 66,
      level: '3',
      gu: {
        header: 'અત્તર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'इत्र',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'perfume',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Parfüm',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 67,
      level: '3',
      gu: {
        header: 'યુદ્ધ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'युद्ध',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'war',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Krieg',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 68,
      level: '3',
      gu: {
        header: 'પદ્માસન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पद्मासन',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'lotus pose',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Lotussitz',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 69,
      level: '3',
      gu: {
        header: 'દ્વીપ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'द्वीप',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'island',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Insel',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 70,
      level: '3',
      gu: {
        header: 'ચંદ્ર',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'चाँद',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'moon',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Mond',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 71,
      level: '3',
      gu: {
        header: 'દ્રાક્ષ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अंगूर',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'grapes',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Trauben',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 72,
      level: '3',
      gu: {
        header: 'નૈવેદ્ય',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'प्रस्ताव',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'offering',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Prasad, Opferspeise',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 73,
      level: '3',
      gu: {
        header: 'શ્વાન',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'कुत्ता',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'dog',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Hund',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 74,
      level: '3',
      gu: {
        header: 'નિશ્ચય',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'निश्चय',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'determination',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Bestimmung',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 75,
      level: '3',
      gu: {
        header: 'વિદ્યાર્થી',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'विद्यार्थी',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'student',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Student',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    }
  ],
  Sentences: [
    {
      serial: 76,
      level: '1',
      gu: {
        header: 'હું જઉં છું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं जा रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am going.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich gehe (gerade).',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 77,
      level: '1',
      gu: {
        header: 'હું લખું છું',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं लिख रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am writing.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich schreibe (gerade).',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 78,
      level: '1',
      gu: {
        header: 'તેણે ખાધું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'उसने खाया ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'She/ He ate.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie / Er aß.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 79,
      level: '1',
      gu: {
        header: 'વરસાદ આવશે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'बारिश आएगी ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'It will rain.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Es wird regnen. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 80,
      level: '1',
      gu: {
        header: 'સૂરજ આથમે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सूरज ढल रहा है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sun is setting.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Die Sonne geht unter.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 81,
      level: '1',
      gu: {
        header: 'આ બસ જશે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'यह बस जायेगी ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'This Bus will leave. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dieser Bus wird abfahren.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 82,
      level: '1',
      gu: {
        header: 'તેઓ પૂજા કરે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वे पूजा कर रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'They are worshiping.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie verehren/beten an. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 83,
      level: '1',
      gu: {
        header: 'બધાં સાંભળે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सब सुन रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'All are listening.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Alle hören zu. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 84,
      level: '1',
      gu: {
        header: 'દાદા બેઠા હતા. ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दादा बैठे थे ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Dada was sitting.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dada hat gesessen.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 85,
      level: '1',
      gu: {
        header: 'મારે છૂટવું છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मुझे छूटना है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I want liberation.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich möchte Befreiung. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 86,
      level: '2',
      gu: {
        header: 'હું ઘરે જઉં છું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं घर जा रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am going home. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich gehe nach Hause.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 87,
      level: '2',
      gu: {
        header: 'હું ચોપડીમાં લખું છું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं किताब में लिख रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am writing in a book. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich schreibe in ein Buch.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 88,
      level: '2',
      gu: {
        header: 'તેણે સફરજન ખાધું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'उसने सेब खाया ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'She/ He ate an apple. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie/er hat einen Apfel gegessen. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 89,
      level: '2',
      gu: {
        header: 'હમણાં વરસાદ આવશે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अभी बारिश आएगी ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'It will rain soon.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Es wird gleich regnen. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 90,
      level: '2',
      gu: {
        header: 'સૂરજ આ દિશામાં આથમે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सूरज इस दिशा में ढलता है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sun sets in this direction.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Die Sonne geht in dieser Richtung unter.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 91,
      level: '2',
      gu: {
        header: 'આ બસ અડાલજ જશે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'यह बस अडालज जायेगी ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'This Bus will go to Adalaj.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dieser Bus fährt nach Adalaj.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 92,
      level: '2',
      gu: {
        header: 'તેઓ ભગવાનની પૂજા કરે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वे भगवान की पूजा कर रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'They are worshiping a Lord.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie beten Gott an. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 93,
      level: '2',
      gu: {
        header: 'બધાં શાંતિથી સાંભળે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सब शांति से सुन रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'All are listening quietly.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Alle hören ruhig zu. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 94,
      level: '2',
      gu: {
        header: 'દાદા ખુરશી પર બેઠા હતા. ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'दादा कुर्सी पर बैठे थे ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Dada was sitting on a chair.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dada saß auf dem Stuhl. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 95,
      level: '2',
      gu: {
        header: 'મારે સંસારથી છૂટવું છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मुझे संसार से छूटना है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I want liberation from the world.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich möchte Befreiung von der Welt. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 96,
      level: '3',
      gu: {
        header: 'હું ટ્રેઇનમાં ઘરે જઉં છું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं ट्रेन में घर जा रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am going home by train.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich fahre mit dem Zug nach Hause. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 97,
      level: '3',
      gu: {
        header: 'હું પેન્સિલથી ચોપડીમાં લખું છું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मैं पेन्सिल से किताब में लिख रही हूँ ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I am writing in a book with a pencil.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Ich schreibe mit einem Bleistift in ein Buch. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 98,
      level: '3',
      gu: {
        header: 'તેણે ચપ્પુથી કાપીને સફરજન ખાધું.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'उसने छुरी से काटकर सेब खाया ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'She/ He cut an apple with knife and ate it. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie/er schnitt den Apfel mit einem Messer auf und aß ihn.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 99,
      level: '3',
      gu: {
        header: 'હમણાં વરસાદ આવશે, છત્રી લઈ લેજો.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'अभी बारिश आएगी,छाता ले लेना ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'It will rain soon, carry umbrella.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Es wird gleich regnen, nehmen sie einen Regenschirm mit. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 100,
      level: '3',
      gu: {
        header: 'સૂરજ પશ્ચિમ દિશામાં આથમે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'पश्चिम दिशा में सूरज ढल रहा है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Sun is setting in west direction. ',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Die Sonne geht im Westen unter. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 101,
      level: '3',
      gu: {
        header: 'આ બસ ચોક્કસ અડાલજ જશે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'यह बस ज़रूर / यक़ीनन अडालज जायेगी ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'This Bus will definitely go to Adalaj.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Dieser Bus fährt definitiv nach Adalaj.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 102,
      level: '3',
      gu: {
        header: 'તેઓ કૃષ્ણ ભગવાનની પૂજા કરે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'वे कृष्ण भगवान की पूजा कर रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'They are worshipping Lord Krishna.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Sie verehren Lord Krishna. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 103,
      level: '3',
      gu: {
        header: 'બધાં શાંતિથી સત્સંગ સાંભળે છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'सब शांति से सत्संग सुन रहे हैं ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'All are listening spiritual discourse/ satsang quietly.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Alle hören dem Satsang in Ruhe zu. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 104,
      level: '3',
      gu: {
        header: 'જ્ઞાનીપુરુષ દાદાશ્રી ખુરશી પર બેઠા હતા. ',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'ज्ञानीपुरुष दादाश्री कुर्सी पर बैठे थे ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'Gnani Purush Dadashri was sitting on a chair.',
        subheader: '',
        speak: ''
      },
      de: {
        header: 'Gnani Purush Dadashri saß auf einem Stuhl. ',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    },
    {
      serial: 105,
      level: '3',
      gu: {
        header: 'મારી સંસારથી છૂટવાની તીવ્ર ઇચ્છા છે.',
        subheader: '',
        speak: ''
      },
      hi: {
        header: 'मेरी संसार से छूटने की तीव्र इच्छा है ।',
        subheader: '',
        speak: ''
      },
      en: {
        header: 'I intensely wish to liberate from the world.',
        subheader: '',
        speak: ''
      },
      de: {
        header:
          'Ich habe den starken Wunsch, Befreiung von der Welt zu erlangen.',
        subheader: '',
        speak: ''
      },
      imgUrl: ''
    }
  ]
}
export default flashcards
