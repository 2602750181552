import Utils from '../util/Utils'

const practicePlayPages = [
  {
    categoryId: 'building-blocks',
    id: 'learnkakko',
    seqNumber: 1,
    name: {
      en: 'Kakko',
      hi: 'कक्को',
      gu: 'કક્કો'
    },
    description: {
      en: 'Practice & learn to identify Gujarati alphabets (Kakko).',
      hi: 'अभ्यास करे और गुजराती अक्षर (कक्को) की पहचान करना सीखें |',
      gu: 'પ્રેક્ટિસ કરો અને ગુજરાતી મૂળાક્ષરો (કક્કો) ઓળખવાનું શીખો.'
    }
  },
  {
    categoryId: 'building-blocks',
    id: 'learnbarakhadi',
    seqNumber: 2,
    name: {
      en: 'Barakhadi',
      hi: 'बाराखड़ी',
      gu: 'બારાખડી'
    },
    description: {
      en: 'Practice the formation of Gujarati barakhadi characters.',
      hi: 'गुजराती बाराखड़ी वर्णों के निर्माण का अभ्यास करें |',
      gu: 'ગુજરાતી બારખડી અક્ષરોની રચનાની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'building-blocks',
    id: 'jointLetters',
    seqNumber: 3,
    name: {
      en: 'Joint Letters',
      hi: 'जुड़े हुए अक्षर',
      gu: 'સંયુક્ત અક્ષરો'
    },
    description: {
      en: 'Practice the formation of Joint & Special characters in Gujarati.',
      hi: 'गुजराती में संयुक्त और विशेष वर्णों के निर्माण का अभ्यास करें |',
      gu: 'ગુજરાતીમાં સંયુક્ત અને વિશેષ અક્ષરોની રચનાની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'vocabulary',
    id: 'flashcards',
    seqNumber: 1,
    name: {
      en: 'Flash Cards',
      hi: 'फ्लेश कार्ड्स',
      gu: 'ફ્લેશ કાર્ડ્સ'
    },
    description: {
      en: 'Use the new flash cards to learn Gujarati Words, Sentences & Characters.',
      hi: 'गुजराती शब्द, वाक्य, और वर्ण सिखने के लिए नए फ्लेश कार्ड्स का उपयोग करें |',
      gu: 'ગુજરાતી શબ્દો, વાક્યો અને અક્ષરો શીખવા માટે નવા ફ્લેશ કાર્ડ્સનો ઉપયોગ કરો.'
    }
  },
  {
    categoryId: 'vocabulary',
    id: 'matchwords',
    seqNumber: 2,
    name: {
      en: 'Match Words',
      hi: 'शब्द मिलाए',
      gu: 'શબ્દો મેળાવો'
    },
    description: {
      en: 'Practice learning Gujarati words & meaning through a match game.',
      hi: 'मैच खेल के माध्यम से गुजराती शब्द और अर्थ सीखने का अभ्यास करें |',
      gu: 'મેચની રમત દ્વારા ગુજરાતી શબ્દો અને અર્થ શીખવાની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'vocabulary',
    id: 'buildword',
    seqNumber: 3,
    name: {
      en: 'Build Words',
      hi: 'शब्द बनाए',
      gu: 'શબ્દો બનાવો'
    },
    description: {
      en: 'Practice building Gujarati words alphabet by alphabet.',
      hi: 'एक एक अक्षर मिलाकर गुजराती शब्द निर्माण का अभ्यास करें |',
      gu: 'એક એક અક્ષર મિલાવીને ગુજરાતી શબ્દ બનાવવાની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'vocabulary',
    id: 'jointWords',
    seqNumber: 4,
    name: {
      en: 'Find Joint Letter Words',
      hi: 'अक्षर जोड़कर खोजे',
      gu: 'સંયુક્ત શબ્દો શોધો'
    },
    description: {
      en: 'Learn to identify Joint & Special characters in words.',
      hi: 'संयुक्त और विशेष वर्णों को शब्दों में पहचानना सीखें |',
      gu: 'સંયુક્ત અને વિશિષ્ટ અક્ષરોને ઓળખવાનું શીખો.'
    }
  },
  {
    categoryId: 'vocabulary',
    id: 'matchjointwords',
    seqNumber: 5,
    name: {
      en: 'Match Joint Words',
      hi: 'अक्षर जोड़कर शब्द मिलायिए',
      gu: 'સંયુક્ત શબ્દો મેળાવો'
    },
    description: {
      en: 'Identify Gujarati words alphabet by alphabet through a match game.',
      hi: 'मैच खेल के माध्यम गुजराती शब्दों के प्रत्येक अक्षर की पहचान करें |',
      gu: 'મેચ ગેમ દ્વારા ગુજરાતી શબ્દોનાં મૂળાક્ષરોને ઓળખો.'
    }
  },
  {
    categoryId: 'phrases',
    id: 'matchtrimantra',
    seqNumber: 1,
    name: {
      en: 'Match Trimantra',
      hi: 'त्रिमंत्र मिलाएँ',
      gu: 'ત્રિમંત્ર જોડો'
    },
    description: {
      en: "Let's learn to write Trimantra in Gujarati through a match game.",
      hi: 'आइए एक मैच गेम के माध्यम से गुजराती में त्रिमंत्र लिखना सीखें |',
      gu: 'મેચની રમત દ્વારા ગુજરાતીમાં ત્રિમંત્ર લખતા શીખીએ.'
    }
  },
  {
    categoryId: 'phrases',
    id: 'matchnavkalamo',
    seqNumber: 2,
    name: {
      en: 'Match Nav Kalmo',
      hi: 'नौ कलमें मिलाएँ',
      gu: 'નવ કલમો જોડો'
    },
    description: {
      en: "Let's learn to write Nav Kalamo in Gujarati through a match game.",
      hi: 'आइए एक मैच गेम के माध्यम से नव कलमो को गुजराती में लिखना सीखें |',
      gu: 'મેચની રમત દ્વારા ગુજરાતીમાં નવ કલમો લખતા શીખીએ.'
    }
  },
  {
    categoryId: 'phrases',
    id: 'sentenceconstruction',
    seqNumber: 3,
    name: {
      en: 'Versatility of Gujarati Language',
      hi: 'गुजराती भाषा की अलंकृतता',
      gu: 'ગુજરાતી ભાષાની વિવિધતા'
    },
    description: {
      en: 'Play a unique fill in the blanks game covering versatility of Gujarati language.',
      hi: 'भाषा की विविधता को कवर करने वाला एक अनोखा खेल खेले |',
      gu: 'ભાષાની વિવિધતાને આવરી લેતી એક અનોખી રમત રમીએ.'
    }
  },
  {
    categoryId: 'numbers-and-colors',
    id: 'fillintheblanksForNumbers',
    seqNumber: 1,
    name: {
      en: 'Fill In The Blanks - Numbers',
      hi: 'अंक शब्द मिलन',
      gu: 'ખાલી જગ્યા પૂરો - અંકો'
    },
    description: {
      en: 'Practice Gujarati numbers in words & figures through reading & listening.',
      hi: 'पढने और सुनने के माध्यम से गुजराती अंको का अभ्यास करें |',
      gu: 'વાંચન અને સાંભળીને શબ્દો અને આંકડાઓમાં ગુજરાતી સંખ્યાઓનો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'numbers-and-colors',
    id: 'matchnumbers',
    seqNumber: 2,
    name: {
      en: 'Match Numbers',
      hi: 'अंक मिलाए',
      gu: 'અંકોને મેળાવો'
    },
    description: {
      en: 'Practice Gujarati numbers 1-10 through a simple match game.',
      hi: 'एक आसान मैच खेल के माध्यम से गुजराती संख्या 1 से 10 का अभ्यास करें |',
      gu: 'એક સરળ મેચ ગેમ દ્વારા ગુજરાતી નંબર 1 થી 10 નો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'numbers-and-colors',
    id: 'matchcolors',
    seqNumber: 3,
    name: {
      en: 'Match Color',
      hi: 'रंगो का मिलन करो',
      gu: 'રંગો જોડો'
    },
    description: {
      en: 'Identify the color & their Gujarati name through a match game.',
      hi: 'एक मैच खेल के माध्यम से रंग और उनके गुजराती नाम पहचाने |',
      gu: 'મેચ ગેમ દ્વારા રંગ અને તેના ગુજરાતી નામને ઓળખો.'
    }
  },
  {
    categoryId: 'numbers-and-colors',
    id: 'colors-fill-blanks',
    seqNumber: 4,
    name: {
      en: 'Fill In The Blanks - Colors',
      hi: 'सही रंग चुनो',
      gu: 'ખાલી જગ્યા પૂરો - રંગો'
    },
    description: {
      en: 'Practice Gujarati colour names with images & examples.',
      hi: 'छवियों और उदाहरणों के साथ गुजराती रंग के नामों का अभ्यास करें |',
      gu: 'ફોટા અને ઉદાહરણો સાથે ગુજરાતી રંગના નામોનો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'interrogatives',
    seqNumber: 1,
    name: {
      en: 'Interrogatives',
      hi: 'प्रश्नवाचक शब्द',
      gu: 'પ્રશ્નાર્થ શબ્દો'
    },
    description: {
      en: 'Practice the what/why/how/when.. of Gujarati!',
      hi: 'गुजराती के प्रश्नवाचक शब्दों का अभ्यास करें |',
      gu: 'ગુજરાતીનાં પ્રશ્નવાચક શબ્દોની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'genders',
    seqNumber: 2,
    name: {
      en: 'Genders',
      hi: 'लिंग',
      gu: 'લિંગ'
    },
    description: {
      en: 'Practice use of words with different gender in sentences.',
      hi: 'भिन्न लिंग वाले शब्दों का वाक्यों में अभ्यास करें |',
      gu: 'અલગ લિંગ વાળા શબ્દોની વાક્યોમાં પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'singular-plural',
    seqNumber: 3,
    name: {
      en: 'Singular Plural',
      hi: 'एकवचन बहुवचन',
      gu: 'એકવચન - બહુવચન'
    },
    description: {
      en: 'Learn to apply singular/plural of words in Gujarati sentence formation.',
      hi: 'गुजराती वाक्य निर्माण में शब्दों के एकवचन / बहुवचन लगाना सीखें |',
      gu: 'ગુજરાતી વાક્ય રચનામાં શબ્દોના એકવચન / બહુવચનને શીખો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'pronouns',
    seqNumber: 4,
    name: {
      en: 'Pronouns',
      hi: 'सर्वनाम',
      gu: 'સર્વનામ'
    },
    description: {
      en: 'Practice the use of Gujarati pronouns.',
      hi: 'गुजराती सर्वनामो के प्रयोग का अभ्यास करें |',
      gu: 'ગુજરાતી સર્વનામના ઉપયોગની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'future-tense',
    seqNumber: 5,
    name: {
      en: 'Future Tense',
      hi: 'भविष्य काल',
      gu: 'ભવિષ્ય કાળ'
    },
    description: {
      en: 'Practice future tense sentence formation.',
      hi: 'भविष्य काल के वाक्य निर्माण का अभ्यास करें |',
      gu: 'ભવિષ્ય કાળના વાક્ય રચનાનો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'present-tense',
    seqNumber: 6,
    name: {
      en: 'Present Tense',
      hi: 'वर्तमान काल',
      gu: 'વર્તમાન કાળ'
    },
    description: {
      en: 'Practice present tense sentence formation.',
      hi: 'वर्तमान काल के वाक्य निर्माण का अभ्यास करें |',
      gu: 'વર્તમાન કાળના વાક્ય રચનાનો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'past-tense',
    seqNumber: 7,
    name: {
      en: 'Past Tense',
      hi: 'भूतकाल',
      gu: 'ભૂતકાળ'
    },
    description: {
      en: 'Practice past tense sentence formation.',
      hi: 'भूतकाल के वाक्य निर्माण का अभ्यास करें |',
      gu: 'ભૂતકાળના વાક્ય રચનાનો અભ્યાસ કરો.'
    }
  },

  {
    categoryId: 'basic-grammar',
    id: 'identifytenses',
    seqNumber: 8,
    name: {
      en: 'Identify Tenses',
      hi: 'काल पहचाने',
      gu: 'કાળ ઓળખો'
    },
    description: {
      en: 'Learn to identify the tenses in Gujarati sentence.',
      hi: 'गुजराती वाक्य में काल की पहचान करना सीखें |',
      gu: 'ગુજરાતી વાક્યોમાં કાળ ઓળખતા શીખો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'useful-words',
    seqNumber: 9,
    name: {
      en: 'Useful Words',
      hi: 'सहायक शब्द',
      gu: 'ઉપયોગી શબ્દો'
    },
    description: {
      en: 'Practice prepositions & conjunctions of Gujarati language.',
      hi: 'गुजराती भाषा के पूर्वसर्गो और संयोजनों का अभ्यास करें |',
      gu: 'ગુજરાતી નામયોગી અને સંયોજનોનો અભ્યાસ કરો.'
    }
  },
  {
    categoryId: 'basic-grammar',
    id: 'sentenceforming',
    seqNumber: 10,
    name: {
      en: 'Sentence Forming',
      hi: 'वाक्य रचना',
      gu: 'વાક્ય રચના'
    },
    description: {
      en: 'Guess & start translating sentences into Gujarati with the help of word options.',
      hi: 'अनुमान लगाएं और शब्द विकल्प की मदद से वाक्यों का गुजराती में अनुवाद करें |',
      gu: 'અનુમાન લગાવો અને શબ્દ વિકલ્પોની મદદથી વાક્યોનું ગુજરાતીમાં અનુવાદ કરો.'
    }
  },
  {
    categoryId: 'fun-games',
    id: 'jumbledWordPuzzle',
    seqNumber: 1,
    name: {
      en: 'Jumbled Word Puzzle',
      hi: 'उलझे हुए शब्दों का पज़ल',
      gu: 'ગૂંચવાયેલા શબ્દોની પઝલ'
    },
    description: {
      en: 'Play a jumbled word puzzle game, but in Gujarati!',
      hi: 'उलझे हुए शब्दों की पहेली गेम खेलें, गुजराती में |',
      gu: 'ગૂંચવાયેલી શબ્દ પઝલની ગેમ રમો, ગુજરાતીમાં !'
    }
  },
  {
    categoryId: 'fun-games',
    id: 'wordsearch',
    seqNumber: 2,
    name: {
      en: 'Word Search',
      hi: 'शब्द खोज',
      gu: 'શબ્દ શોધો'
    },
    description: {
      en: 'Search & identify Gujarati words in a grid word search game.',
      hi: 'ग्रिड शब्द खोज गेम में गुजराती शब्द खोजें और पहचाने |',
      gu: 'ગ્રીડ વર્ડ સર્ચ ગેમમાં ગુજરાતી શબ્દો શોધો અને ઓળખો.'
    }
  },
  {
    categoryId: 'fun-games',
    id: 'paintdada',
    seqNumber: 3,
    name: {
      en: 'Paint Dada',
      hi: 'दादा को रंगना',
      gu: 'દાદા રંગો'
    },
    description: {
      en: 'Guess the correct characters to paint Dada!',
      hi: 'दादा को पेंट करने लिए सही अक्षरों का अनुमान लगाएं |',
      gu: 'દાદાને રંગવા માટે યોગ્ય અક્ષરોનો અંદાજ લગાવો.'
    }
  },
  {
    categoryId: 'spoken',
    id: 'fortune-wheel',
    seqNumber: 1,
    name: {
      en: 'Fortune Wheel',
      hi: 'भाग्य का पहिया',
      gu: 'ફોર્ચ્યુન વ્હીલ'
    },
    description: {
      en: 'Practice words & sentence making with self or in group through a unique fortune wheel.',
      hi: 'एक अनोखे फोर्च्यून व्हील के माध्यम से स्वयं या समूह में, शब्द और वाक्य बनाने का अभ्यास करें |',
      gu: 'ફોર્ચ્યુન વ્હીલ દ્વારા સ્વયં અથવા સમૂહમાં શબ્દો અને વાક્ય બનાવવાની પ્રેક્ટિસ કરો.'
    }
  },
  {
    categoryId: 'spoken',
    id: 'listen-speak',
    seqNumber: 1,
    name: {
      en: 'Listen and Speak',
      hi: 'सुनो और बोलो',
      gu: 'સાંભળો અને બોલો'
    },
    description: {
      en: 'Record your voice while saying the given sentence in order to practice listening and speaking.',
      hi: 'सुनने और बोलने का अभ्यास करने के लिए दिए गए वाक्य को बोलते समय अपनी आवाज़ रिकॉर्ड करें।',
      gu: 'સાંભળવાની અને બોલવાની પ્રેક્ટિસ કરવા માટે આપેલ વાક્ય બોલતી વખતે તમારો અવાજ રેકોર્ડ કરો.'
    }
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'ghantnaad',
    name: { en: 'Ghantnaad', hi: 'घंटनाद', gu: 'ઘંટનાદ' },
    preQuizView: { type: 'image' },
    video: '489920911',
    pdf: 'https://drive.google.com/file/d/1sdGZJJBUT7r_nAFwAPMlywkgGmXq-Z97/view?usp=sh',
    backgroundMusic:
      'https://stream52.hearthis.at/b9685bedc7a7ee71255067fd30ace2ad.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'banyu-te-j-nyay',
    name: {
      en: 'Banyu Te J Nyay',
      hi: 'बन्यु ते ज न्याय',
      gu: 'બન્યું તે જ ન્યાય'
    },
    preQuizView: { type: 'image' },
    video: '489242090',
    pdf: 'https://drive.google.com/file/d/1rqjjgvv-JR_yuexlh9Iv2viW5_e748Lu/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/6cbc2c38873b8915ee38bb0f9e809949.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'dharm-no-marm',
    name: {
      en: 'Dharma no Marm',
      hi: 'धर्म नो मर्म',
      gu: 'ધર્મ નો મર્મ'
    },
    preQuizView: { type: 'image' },
    video: '496983505',
    pdf: 'https://drive.google.com/file/d/14Gq9RVFwbUx_bP4m5-aXvWNX3_epeWfV/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/079c189cc41d9831126eafdb09e92e83.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'maafi-maangvanu-mahatva',
    name: {
      en: 'Maafi Maangvanu Mahatva',
      hi: 'माफ़ी मांगवानु महत्व',
      gu: 'માફી માંગવાનું મહત્વ'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '497138747',
    pdf: 'https://drive.google.com/file/d/1uSibmr0O-bN_qo-OELbTsPjIdzAr7hhE/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/c385d794dabc7cf02859a08d615e05f8.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'why-should-we-not-eat-eggs',
    name: {
      en: 'Why Should We Not Eat Eggs?',
      hi: 'एग्स क्यों नहीं खाना चाहिए?',
      gu: 'ઈંડા કેમ ના ખાવા જોઈએ?'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '568852644',
    pdf: 'https://drive.google.com/file/d/1APrKEGQmBDqN3bPShNjRTGIm30q2_IYS/view?usp=sharing',
    backgroundMusic:
      'https://stream74.hearthis.at/ee96df1707710ac98044e561c479dde5.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'karta-kaun',
    name: { en: 'Karta Kaun', hi: 'कर्ता कौन', gu: 'કર્તા કોણ' },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '506664963',
    pdf: 'https://drive.google.com/file/d/1DIYPfYpiW36MNZ7JKQc38Zo9-SX11A65/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/b9685bedc7a7ee71255067fd30ace2ad.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'jj-100-part-09',
    name: {
      en: 'JJ-100 Part-09',
      hi: 'JJ-100 भाग-09',
      gu: 'JJ-100 ભાગ-09'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '508263493',
    pdf: 'https://drive.google.com/file/d/130LOn9mi4YKJdAV2lRiFCzYlJ9otOmvK/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/6cbc2c38873b8915ee38bb0f9e809949.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'jj-100-part-10',
    name: {
      en: 'JJ-100 Part-10',
      hi: 'JJ-100 भाग-10',
      gu: 'JJ-100 ભાગ-10'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '514186518',
    pdf: 'https://drive.google.com/file/d/1qhTK_tjLgjI5R6SufDQ5RqdJhvEHuYzA/view?usp=sharing',
    backgroundMusic:
      'https://stream52.hearthis.at/079c189cc41d9831126eafdb09e92e83.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'mahavideh-javaanu',
    name: {
      en: 'Mahavideh Javaanu',
      hi: 'महाविदेह जवानु',
      gu: 'મહાવિદેહ જવાનું'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '539554176',
    pdf: 'https://drive.google.com/file/d/16jreJ48oDE44BO3W7OFj4XN63Nz4ceAI/view?usp=shar',
    backgroundMusic:
      'https://stream52.hearthis.at/c385d794dabc7cf02859a08d615e05f8.mp3'
  },
  {
    categoryId: 'satsang-interpretation',
    id: 'karm-bandhan-kai-rite',
    name: {
      en: 'Karma bandhan kai rite',
      hi: 'कर्म बंधन कई रीते',
      gu: 'કર્મ બંધન કઈ રીતે'
    },
    preQuizView: { type: 'image' },
    postQuizView: { type: 'image' },
    video: '573628797',
    pdf: 'https://drive.google.com/file/d/1lb8luPPkQCcUCeMyOKdCJzNtDhGQWg6I/view?usp=sharing',
    backgroundMusic:
      'https://stream74.hearthis.at/ee96df1707710ac98044e561c479dde5.mp3'
  },
  {
    categoryId: 'quizzes',
    id: 'adjust-everywhere',
    seqNumber: 1,
    name: {
      en: 'Adjust Everywhere',
      hi: 'એડજેસ્ટ એવરીવ્હેર',
      gu: 'एडजस्ट एवरीव्हेर'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/1gSMbToQ1xiO0IACKFy9anzSrT6ztwTia/view?usp=drive_link'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/b9685bedc7a7ee71255067fd30ace2ad.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#b4b837'
  },
  {
    categoryId: 'quizzes',
    id: 'vhala-niruma',
    seqNumber: 2,
    name: {
      en: 'Beloved Niruma',
      hi: 'વ્હાલા નીરુમા ',
      gu: 'प्रिय नीरुमा'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/157tp4lY9ivcMZfW8qpBuxiDxCj6FYQoy/view?usp=sharing'
    },
    video: '',
    pdf: '',
    image: '',
    backgroundMusic:
      'https://stream52.hearthis.at/6cbc2c38873b8915ee38bb0f9e809949.mp3',

    cardThemeColor: '#da8703'
  },
  {
    categoryId: 'quizzes',
    id: 'akram-express-jul-2017',
    seqNumber: 3,
    name: {
      en: 'Common Sense',
      hi: 'अडजस्टमेन्ट से "कोमनसेन्स" खिलती हे ',
      gu: 'અડજેસ્ટમેન્ટથી ખીલે "કોમનસેન્સ"'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/1eCWIoiCngzTi6-9w5OXp1S8OUVOgpHVF/view'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/079c189cc41d9831126eafdb09e92e83.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#ed3d71'
  },
  {
    categoryId: 'quizzes',
    id: 'dadai-vision-bhakti-pad',
    seqNumber: 4,
    name: {
      en: 'Dadai Vision',
      hi: 'दादाई विजन',
      gu: 'દાદાઈ વિઝન'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'video',
      id: 'tyy4SuxCUVU'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/c385d794dabc7cf02859a08d615e05f8.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#a05a14'
  },
  {
    categoryId: 'quizzes',
    id: 'gnani-purush-1-pg-56-70',
    seqNumber: 5,
    name: {
      en: 'Gnani Purush-I',
      hi: 'ज्ञानी पुरुष-।',
      gu: 'જ્ઞાની પુરુષ-I'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/1mx_Tq0sgk5uUdrfUUCEEtc_7qG1qq-7p/view?usp=drive_link'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream74.hearthis.at/ee96df1707710ac98044e561c479dde5.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#332929'
  },
  {
    categoryId: 'quizzes',
    id: 'gnani-purush-1-pg-71-80',
    seqNumber: 6,
    name: {
      en: 'Gnani Purush-II',
      hi: 'ज्ञानी पुरुष-।।',
      gu: 'જ્ઞાની પુરુષ-II'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/18eaIOeVuJhvq6up5YqsRubp9S79NEr3q/view?usp=sharing'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/b9685bedc7a7ee71255067fd30ace2ad.mp3',

    cardThemeColor: '#332929'
  },
  {
    categoryId: 'quizzes',
    id: 'jagat-kalyan',
    seqNumber: 7,
    name: {
      en: 'Jagat Kalyaan',
      hi: 'जगत कल्याण',
      gu: 'જગત કલ્યાણ'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'video',
      id: 'Guad7YUjUwY'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/6cbc2c38873b8915ee38bb0f9e809949.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#1e120a'
  },
  {
    categoryId: 'quizzes',
    id: 'navratri',
    seqNumber: 8,
    name: {
      en: 'Navratri',
      hi: 'नवरात्री',
      gu: 'નવરાત્રી'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'video',
      id: 'K-zOZneIh00'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/079c189cc41d9831126eafdb09e92e83.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#c15a18'
  },
  {
    categoryId: 'quizzes',
    id: 'niruma-gnanday',
    seqNumber: 9,
    name: {
      en: 'Niruma Gnanday',
      hi: 'निरुमा ज्ञानदिन',
      gu: 'નીરુમા જ્ઞાનદિવસ'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'video',
      id: 'y_3tSsxzhak'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/c385d794dabc7cf02859a08d615e05f8.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#1f2022'
  },
  {
    categoryId: 'quizzes',
    id: 'niruma-aptsinchan',
    seqNumber: 10,
    name: {
      en: "Niruma's Aptsinchan",
      hi: 'निरुमा का आप्तसिंचन',
      gu: 'નીરુમાનું આપ્તસિંચન'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/1MHHix8TDeqhyTOQIdZBl1aaTgH2isi6m/view?usp=sharing'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream74.hearthis.at/ee96df1707710ac98044e561c479dde5.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#924e3f'
  },
  {
    categoryId: 'quizzes',
    id: 'abhipray-etle-chashma',
    seqNumber: 11,
    name: {
      en: 'Opinions Are Like Eyeglasses',
      hi: 'અભિપ્રાય એટલે ચશ્મા',
      gu: 'अभिप्राय मतलब चश्मा'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'pdf',
      id: 'https://drive.google.com/file/d/1pJhNdtNsksB5TlYQeovTrkqruoGxMB2c/view?usp=drive_link'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/b9685bedc7a7ee71255067fd30ace2ad.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#3e8d94'
  },
  {
    categoryId: 'quizzes',
    id: 'satyug-vartshe-simandhar-swamini-bhaktithi',
    seqNumber: 12,
    name: {
      en: 'Simandhar Swami Bhakti',
      hi: 'सीमंधर स्वामी भक्ति',
      gu: 'સીમંધર સ્વામી ભક્તિ'
    },
    description: {
      en: '',
      hi: '',
      gu: ''
    },
    preQuizView: {
      type: 'video',
      id: 'pIxp7EFk__4'
    },
    video: '',
    pdf: '',
    backgroundMusic:
      'https://stream52.hearthis.at/6cbc2c38873b8915ee38bb0f9e809949.mp3',
    postQuizView: {
      type: 'image',
      id: 'end.png'
    },
    cardThemeColor: '#173e75'
  }
]
export default practicePlayPages
